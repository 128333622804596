<template>
  <!-- 头部模块 -->
  <div class="main">
    <header>
      <div class="header-title">比特泊车数据可视化平台</div>
      <div class="curDateTime">
        <div class="curDate">{{ curDate }}</div>
        <div class="curTime">{{ curTime }}</div>
      </div>
    </header>
    <div class="body">
      <!-- top5 -->
      <div class="top5" style="margin-top: -20px;margin-left: 20px;">
        <div class="top5-title">
          <span>泊位分析</span>
        </div>
        <div class="top5-content" style="height: 210px;">
          <div ref="bwfxChart" class="bwfxChart" style="height: 220px;"></div>
          <div style="position: relative;top: -200px;left: 220px;
          color: white;">
            <div style="width: 204px;height: 1px;background-color: rgba(106, 139, 157, 1);"></div>
            <div style="width: 204px;margin: 11px 0px;display: flex;justify-content: space-between;">
              <div>
                <div style="font-size: 20px;font-family: Bb3116;">{{ parseFloat(parkingSpaceInfoData.spaceEmptyCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} </div>
                <div style="display: flex;margin-top: 5px;">
                  <img src="@/assets/screen/bwfx1.png" width="6" height="24" alt=""/>
                  <div style="margin-left: 3px;">
                    <div style="font-size: 12px;">空闲泊位</div>
                    <div style="font-size: 8px;margin-top: 3px;">Idle berths</div>
                  </div>
                </div>
              </div>
              <div style="width: 1px;height: 50px;background-color: rgba(255, 255, 255, 0.40);"></div>
              <div>
                <div style="font-size: 20px;font-family: Bb3116;">{{parseFloat(parkingSpaceInfoData.spaceUseCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div style="display: flex;margin-top: 5px;">
                  <img src="@/assets/screen/bwfx2.png" width="6" height="24" alt=""/>
                  <div style="margin-left: 3px;">
                    <div style="font-size: 12px;">占用泊位</div>
                    <div style="font-size: 8px;margin-top: 3px;">Berth occupancy</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 204px;height: 1px;background-color: rgba(106, 139, 157, 1);"></div>
          </div>
        </div>
        <div class="top5-title">
          <span>车场收入TOP5</span>
        </div>
        <div class="top5-content" style="height: 300px;color: white;margin-top: 12px;">
          <!--统计信息-->
          <div style="display: flex;align-items: center;justify-content: right;">
            <div>
              <div style="color: rgba(255, 255, 255, 0.60);font-size: 12px;">周转率</div>
              <div style="font-size: 26px;font-family: Bb3116;margin-top: 5px;">{{ parkingLotSortData.parkingRate }}</div>
            </div>
            <div style="
            width: 1px;
            height: 44px;
            background-color: rgba(255, 255, 255, 0.2);
            margin: 0px 15px;"
            ></div>
            <div style="margin-right: 10px;">
              <div style="color: rgba(255, 255, 255, 0.60);font-size: 12px;">单泊位平均收益</div>
              <div>
                <div style="font-size: 26px;font-family: Bb3116;margin-top: 5px;">{{ parkingLotSortData.spaceEarn }}</div>
                <div class="item-pic"></div>
              </div>
            </div>
          </div>
          <!--TOP5-->
          <div v-if="parkingLotSortList.length>0">
            <div v-for="(item, index) in parkingLotSortList" :key="index" style="display: flex;padding: 8px 10px;">
              <div :class="`pmno${(index+1)}`">{{(index+1).toString().padStart(2, '0')}}</div>
              <div class="content" style="flex: 1;padding-left: 18px;">
                <div class="title" style="display: flex;">
                  <div style="flex: 1;font-size: 14px;">{{ item.parkingLotName }}</div>
                  <div style="font-size: 14px;font-family: Bb3116;"> {{ parseFloat(item.transactionAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}元</div>
                </div>
                <div style="height: 15px;margin-top: 3px;
                  border: rgba(255, 255, 255, 0.20) 1px solid;">
                  <div :class="`pmBar${index+1}`" style="width: 100%;height: 10px;
                    display: flex;align-items: center;justify-content: center;margin-top: 5px;
                    "></div>
                </div>
              </div>
              <!--            <div class="flag">↑</div>-->
            </div>
          </div>
        </div>
        <div class="top5-title">
          <span>本月巡检任务分析</span>
        </div>
        <div class="top5-content" style="color: white;padding: 24px 0px;">
          <div class="xjrwgk">
            <div style="font-size: 12px;">巡检任务概况</div>
            <div style="display: flex;margin-top: 12px;">
              <div>
                <div style="color: rgba(24, 254, 254, 1);font-size: 30px;font-family: Bb3116;">{{ parseFloat(inspectorTaskAnylizeData.checkPlateNumber).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div style="color: rgba(155, 163, 170, 1);font-size: 10px;margin-top: 8px;">查车牌任务总数</div>
              </div>
              <div style="margin-left: 50px;">
                <div style="color: rgba(7, 246, 160, 1);font-size: 30px;font-family: Bb3116;">{{ parseFloat(inspectorTaskAnylizeData.confirmOutCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div style="color: rgba(155, 163, 170, 1);font-size: 10px;margin-top: 8px;">驶离确认任务总数</div>
              </div>
            </div>
            <div style="display: flex;margin-top: 12px;">
              <div>
                <div style="display: flex;">
                  <div style="font-size: 30px;font-family: Bb3116;">{{ inspectorTaskAnylizeData.checkPlateNumberRate }}</div>
                  <div style="font-size: 10px;color: rgba(155, 163, 170, 1);margin-left: 5px;">%</div>
                </div>
                <div style="color: rgba(155, 163, 170, 1);font-size: 10px;margin-top: 8px;">任务完成率</div>
              </div>
              <div style="margin-left: 50px;">
                <div style="display: flex;">
                  <div style="font-size: 30px;font-family: Bb3116;">{{ inspectorTaskAnylizeData.confirmOutRate }}</div>
                  <div style="font-size: 10px;color: rgba(155, 163, 170, 1);margin-left: 5px;">%</div>
                </div>
                <div style="color: rgba(155, 163, 170, 1);font-size: 10px;margin-top: 8px;">任务响应率</div>
              </div>
            </div>
          </div>
          <div ref="xjrwChart" class="xjrwChart" style="height: 220px;position: relative;top:-200px;left: 270px; "></div>
        </div>
      </div>
      <div style="width: 50%;padding: 0 37px;align-items: center">
        <div class="summary">
          <div class="pic-left"></div>
          <div class="content">
            <div class="summary-item">
              <div class="item-title">今日收益</div>
              <div class="item-val">{{ parseFloat(parkingRevenueData.todayEarnTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
            </div>
            <div class="summary-item">
              <div class="item-title">收缴率</div>
              <div class="item-val">
                <div>{{ parkingRevenueData.payRate }}</div>
                <div class="item-pre">%</div>
              </div>
            </div>
            <div class="summary-item">
              <div class="item-title">昨日收益</div>
              <div class="item-val">
                <div>{{ parseFloat(parkingRevenueData.yesterdayEarnTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div class="item-pic"></div>
              </div>
            </div>
            <div class="summary-item">
              <div class="item-title">本月收益</div>
              <div class="item-val">
                <div>{{ parseFloat(parkingRevenueData.monthEarnTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                <div class="item-pic"></div>
              </div>
            </div>
          </div>
          <div class="pic-right"></div>
        </div>
        <!-- 销售总额 -->
        <div class="total">
          <div class="data1">
            <span>总车场数</span>
            <p>{{ parseFloat(parkingLotTotalResultData.parkingLotTotalCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</p>
          </div>
          <div class="data2">
            <span>总泊位数</span>
            <p>{{ parseFloat(parkingSpaceTotalResultData.spaceTotalCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</p>
          </div>
          <div class="data3">
            <span>用户车辆</span>
            <p>{{ parseFloat(parkingVehicleInResultData.vehicleCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</p>
          </div>
          <div class="data4">
            <span>人员投入数</span>
            <p>{{ parseFloat(personnelTotalCountData.rersonnelCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</p>
          </div>
          <div class="data5">
            <span>设备投入数</span>
            <p>{{ parseFloat(deviceTotalCountData.deviceCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</p>
          </div>
          <div class="data6">
            <span>巡检任务数</span>
            <p>{{ parseFloat(inspectorTaskCountData.checkPlateNumber+inspectorTaskCountData.confirmOutCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</p>
          </div>
          <canvas class="dashed"></canvas>
          <div class="sphere">
            <div class="sphere-bg"></div>
            <div class="sum">
              <span>累计收益</span>
              <p>{{parseFloat(parkingFeeInfoData.totalEarns).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</p>
            </div>
          </div>
          <div class="cicle3"></div>
          <div class="cicle4"></div>
          <div class="cicle5"></div>
          <div class="cicle6"></div>
          <div class="cicle7"></div>
          <div class="cicle8">
            <span>{{ inspectorTaskCountData.collectionRate?inspectorTaskCountData.collectionRate.toString()+'%':'0%' }}</span>
            <p>收缴率</p>
          </div>
          <div class="cicle9">
            <span>{{ parseFloat(parkingFeeInfoData.totalArrearsIncome).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            <p>累计欠费</p>
          </div>
          <div class="cicle10">
            <span>{{ parkingVehicleInResultData.parkingArrangeTime }}</span>
            <p>平均停车时长</p>
          </div>
          <div class="cicle11">
            <span>{{ parseFloat(deviceTotalCountData.tollGateCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
            <p>收费口</p>
          </div>
        </div>
      </div>

      <!-- 今日收入趋势 -->
      <div class="analyse"  style="margin-top: -20px;">
        <div class="analyse-title">
          <span>今日收入趋势</span>
        </div>
        <div ref="jrsrqsChart" class="jrsrqsChart"></div>
        <div class="analyse-title">
          <span>停放时长分析</span>
        </div>
        <div ref="tfscfxChart" class="tfscfxChart"></div>
        <div class="analyse-title">
          <span>今日实时订单</span>
        </div>
        <div class="jrssdd">
          <div class="jrssdd-item" v-for="(item, index) in parkingOrderList" :key="index">
            <div style="display: flex;">
              <div style="font-family: Bb3116;color: #07F7A1;font-size: 16px; display: flex;">{{item.parkingLotName===''?'':item.driveInTime.split(' ')[1].substr(0,5)}}</div>
              <div style="padding-left: 54px;color: white;">{{item.parkingLotName}}</div>
            </div>
            <div style="font-weight: bold;padding-right: 50px;">{{ item.plateNumber }}</div>
          </div>
          <div style="margin-top: 20px;display: flex;">
            <div>
              <div style="color: #9BA3AA;font-size: 10px;">
                路内-累计订单数量
              </div>
              <div>
                <div style="display: flex;align-items: end;">
                  <div style="font-family: Bb3116;font-size: 29px;min-width: 94px;">{{ parseFloat(parkingOrderData.innerTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                  <img src="@/assets/screen/rate1.png" width="94px" height="34px" style="margin-left: 8px;"/>
                </div>
              </div>
            </div>
            <div>
              <div style="color: #9BA3AA;font-size: 10px;">
                路外-累计订单数量
              </div>
              <div style="margin-left: 18px;">
                <div style="display: flex;align-items: end;">
                  <div style="font-family: Bb3116;font-size: 29px;min-width: 94px;">{{ parseFloat(parkingOrderData.outTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
                  <img src="@/assets/screen/rate2.png" width="94px" height="34px" style="margin-left: 8px;"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <canvas ref="rain" class="rain"></canvas>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import * as echarts from "echarts";
import "echarts-liquidfill";
import {
  parkingRevenue,
  parkingSpaceInfo,
  parkingFeeInfo,
  getParkingLotTotalResult,
  getParkingSpaceTotalResult,
  getParkingVehicleInResult,
  searchParkingLotSortList,
  searchParkingFeeChange,
  searchParkingTimeAnylize,
  searchParkingorderList,
  inspectorTaskAnylize,
  inspectorTaskCount,
  deviceTotalCount,
  personnelTotalCount,
} from "@/api/screen";

export default {
  name: "screen",
  components: {
    // BaseChart,
  },
  data() {
    return {
      curDate: '',
      curTime: '',
      parkingRevenueData:{},
      parkingSpaceInfoData:{},
      parkingFeeInfoData:{},
      parkingLotTotalResultData:{},
      parkingSpaceTotalResultData:{},
      parkingVehicleInResultData:{},
      parkingLotSortData:{},
      parkingLotSortList:[],
      parkingFeeChangeData:{},
      parkingTimeAnylizeData:{},
      parkingOrderData:{},
      inspectorTaskAnylizeData:{},
      inspectorTaskCountData:{
        "checkPlateNumber": 0,
        "collectionRate": 0,
        "confirmOutCount": 0
      },
      deviceTotalCountData:{},
      personnelTotalCountData:{},
      parkingOrderList:[],
      userName: "",
      password: "",
      isLoading: false,
      hxwclChartOption: {
        series: [{
          color:['#09828e','#09c2c8'],
          type: 'liquidFill',
          data: [0.78, 0.78],
          radius: '90%',
          outline: {
            show: false
          },
          backgroundStyle: {
            color:'transparent',
            borderColor: '#0ac1c7',
            borderWidth: 1,
            shadowColor: 'rgba(0, 0, 0, 0.4)',
            shadowBlur: 20
          },
          shape: 'path://"M61.3,2c6.2,0,12.1,1.1,17.5,3.4C84.3,7.7,89,10.8,93,14.6c4.1,4,7.3,8.6,9.7,13.8c2.4,5.2,3.5,10.9,3.5,16.9c0,8.1-2.4,16.9-7.1,26.4c-4.7,9.4-9.9,18.2-15.5,26.2c-5.6,8-13.1,17.4-22.4,28.1c-9.3-10.7-16.8-20-22.4-28.1c-5.6-8-10.8-16.8-15.5-26.2c-4.7-9.4-7.1-18.2-7.1-26.4c0-6,1.2-11.6,3.5-16.9c2.4-5.2,5.6-9.8,9.7-13.8c4-3.9,8.8-7,14.2-9.2C49.2,3.1,55,2,61.3,2L61.3,2z"',
          label: {
            normal: {
              position: ['50%', '50%'],
              formatter: function () {
                return '78%';
              },
              textStyle: {
                fontSize: 0.5 * this.w,
                color: '#D94854'
              }
            }
          }
        }]
      },
      w:0,
    };
  },
  created(){
    window.$ = $;
    this.placeholderPic()
  },
  mounted() {
    // this.draw('.shoeChart', 52563, '完成率', 65000, 'rgba(7, 247, 161, 1)')
    // this.draw('.clothesChart', 32563, '完成率', 45000, 'rgba(7, 247, 161, 1)')
    // this.draw('.mzChart', 12563, '完成率', 35000, 'rgba(7, 247, 161, 1)')
    this.rainBg()
    this.dashed()
    // this.drawJrsrqs();
    // this.drawCcsrTop();
    // this.drawTfscfx();
    this.drawBwfx();
    this.drawXjrwfx();
    // this.waterChart('.chart1')
    // this.waterChart(this.$refs['chart2'])
    this.getData();
    this.getDate();
    setInterval(()=>{
      this.getData()
    }, 15*1000)
    const that = this
    window.onresize = () => {
      return (() => {
        that.$refs.rain.onResize()
        that.$refs.bwfxChart.onResize()
        that.$refs.xjrwChart.onResize()
        that.$refs.jrsrqsChart.onResize()
        that.$refs.tfscfxChart.onResize()
      })()
    }
  },
  methods: {
    getDate(){
      setInterval(()=>{
        const now = Date.now();
        const date = new Date(now);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        this.curDate = `${year}-${month}-${day}`;
        this.curTime = `${hour}:${minute}:${second}`;
      },1000);
    },
    getData(){
      parkingRevenue().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingRevenueData = res.returnObject
        }
      });
      parkingSpaceInfo().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingSpaceInfoData = res.returnObject
          this.drawBwfx();
        }
      });
      parkingFeeInfo().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingFeeInfoData = res.returnObject
        }
      });
      getParkingLotTotalResult().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingLotTotalResultData = res.returnObject
        }
      });
      getParkingSpaceTotalResult().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingSpaceTotalResultData = res.returnObject
        }
      });
      getParkingVehicleInResult().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingVehicleInResultData = res.returnObject
        }
      });
      searchParkingLotSortList({parkingDate: this.curDate}).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingLotSortList = res.returnObject.parkingLotSortResultVoList
          if(this.parkingLotSortList.length>5){
            this.parkingLotSortList = this.parkingLotSortList.slice(0,5)
          }
          this.parkingLotSortData = res.returnObject
          for (let i = this.parkingLotSortList.length; i < 5; i++) {
            this.parkingLotSortList.push({
              "parkingLotId": "",
              "parkingLotName": "",
              "spaceUseCount": 0,
              "transactionAmount": 0
            })
          }
          this.drawCcsrTop();
        }
      });
      searchParkingFeeChange({parkingDate: this.curDate}).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingFeeChangeData = res.returnObject
          this.drawJrsrqs();
        }
      });
      searchParkingTimeAnylize().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingTimeAnylizeData = res.returnObject
          this.drawTfscfx();
        }
      });
      searchParkingorderList().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingOrderData = res.returnObject
          this.parkingOrderList = res.returnObject.parkingOrderResultVoList
          this.parkingOrderList = this.parkingOrderList.slice(0, 5)
          for (let i = this.parkingOrderList.length; i < 5; i++) {
            this.parkingOrderList.push({
              "driveInTime": "",
              "parkingLotId": "",
              "parkingLotName": "",
              "parkingOrderIntoId": "",
              "plateNumber": ""
            })
          }
        }
      });
      inspectorTaskAnylize().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.inspectorTaskAnylizeData = res.returnObject
          this.drawXjrwfx()
        }
      });
      inspectorTaskCount().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.inspectorTaskCountData = res.returnObject
        }
      });
      deviceTotalCount().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.deviceTotalCountData = res.returnObject
        }
      });
      personnelTotalCount().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.personnelTotalCountData = res.returnObject;
        }
      });
    },
    doSearch(){},
    // 定义字体大小
    placeholderPic() {
      this.w = document.documentElement.clientWidth / 80;
      document.documentElement.style.fontSize = this.w + 'px';
    },

// 水波图
waterChart(ele) {
  var myChart = echarts.init(document.querySelector(ele));
  console.log('waterChart:12')
  var option = {
    series: [{
      color:['#09828e','#09c2c8'],
      type: 'liquidFill',
      data: [0.78, 0.78],
      radius: '90%',
      outline: {
        show: false
      },
      backgroundStyle: {
        color:'transparent',
        borderColor: '#0ac1c7',
        borderWidth: 1,
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowBlur: 20
      },
      shape: 'path://"M61.3,2c6.2,0,12.1,1.1,17.5,3.4C84.3,7.7,89,10.8,93,14.6c4.1,4,7.3,8.6,9.7,13.8c2.4,5.2,3.5,10.9,3.5,16.9c0,8.1-2.4,16.9-7.1,26.4c-4.7,9.4-9.9,18.2-15.5,26.2c-5.6,8-13.1,17.4-22.4,28.1c-9.3-10.7-16.8-20-22.4-28.1c-5.6-8-10.8-16.8-15.5-26.2c-4.7-9.4-7.1-18.2-7.1-26.4c0-6,1.2-11.6,3.5-16.9c2.4-5.2,5.6-9.8,9.7-13.8c4-3.9,8.8-7,14.2-9.2C49.2,3.1,55,2,61.3,2L61.3,2z"',
      label: {
        normal: {
          position: ['50%', '50%'],
          formatter: function () {
            return '78%';
          },
          textStyle: {
            fontSize: 0.5 * this.w,
            color: '#D94854'
          }
        }
      }
    }]
  }
  myChart.setOption(option)
},

draw(ele, val, con, max, color) {
  var chart = echarts.init(document.querySelector(ele))
  var value = val
  var option = {
    grid: {
      left: '20%',
      top: '0',
      right: '20%',
      bottom: '0'
    },
    "xAxis": {
      type: 'value',
      "splitLine": {
        "show": false
      },
      "axisLine": {
        "show": false
      },
      "axisLabel": {
        "show": false
      },
      "axisTick": {
        "show": false
      }
    },
    "yAxis": [{
      "type": "category",
      "inverse": false,
      "data": [],
      "axisLine": {
        "show": false
      },
      "axisTick": {
        "show": false
      },
      "axisLabel": {
        show: false
      }
    }],
    "series": [
      {
        type: 'pictorialBar',
        data: [value],
        itemStyle: {
          normal: {
            color: color
          }
        },
        symbolRepeat: 'fixed',
        symbolClip: true,
        symbolSize: [0.5 * this.w, this.w],
        symbol: 'roundRect',
        label: {
          show: true,
          position: 'left',
          formatter: function () {
            return con
          },
          color: '#fff',
          fontSize: 0.7 * this.w,
        },
        z: 1000
      },
      {
        type: 'pictorialBar',
        itemStyle: {
          normal: {
            color: '#193040'
          }
        },
        data: [max],
        animationDuration: 0,
        symbolRepeat: 'fixed',
        // symbolMargin: '20%',
        symbol: 'roundRect',
        symbolSize: [0.5 * this.w, this.w],
        label: {
          show: true,
          position: 'right',
          formatter: function () {
            return Math.floor(val * 100 / max) + '%'
          },
          color: '#fff',
          fontSize: 0.7 * this.w,
        }
      }
    ]
  };
  chart.setOption(option)
},
// 绘制巡检任务分析环形图
drawXjrwfx(){
  var data = parseFloat(this.inspectorTaskAnylizeData.totalCompletionRate)
  var chart = echarts.init(document.querySelector('.xjrwChart'))
  var option = {
    // 额外文字
    graphic: [
      // 泊位利用率数值
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "15%",
        top: "58%",
        style: {
          text: `${data}`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 20,
          fontFamily: 'Bb3116',
          lineHeight: 20,
        },
      },
      // %
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "24%",
        top: "57%",
        style: {
          text: `%`,
          textAlign: "center",
          fill: "#677681", //文字的颜色
          fontSize: 9,
          lineHeight: 9,
        },
      },
      // 泊位利用率
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "12%",
        top: "21%",
        style: {
          text: `总体完成度`,
          textAlign: "center",
          fill: "rgba(255, 255, 255, 1)", //文字的颜色
          fontSize: 12,
          lineHeight: 12,
        },
      },
    ],
    series: [
      // 主饼图
      {
        type: 'pie',
        center: ['20%', '60%'],
        radius: [49, 55],
        x: '0%',
        startAngle: -30,
        tooltip: { show: false },
        itemStyle: {
          borderRadius: 10,
          borderColor: 'rgba(6, 255, 165, 0)',
          borderWidth: 10
        },
        data: [
          {
            name: '达成率',
            value: data,
            itemStyle: { color: 'rgba(255, 255, 255, 1)' },
            hoverAnimation: false,
            label: {
              show: false,
              position: 'center',
              textStyle: {
                fontFamily: '微软雅黑',
                fontWeight: 'bolder',
                color: '#B7E1FF',
                fontSize: 24
              }
            },
            labelLine: {
              show: false
            }
          },
          {
            name: '79%',
            value: 100-data,
            itemStyle: { color: 'rgba(128, 132, 135, 1)' },
            hoverAnimation: false,
            label: {
              show: false,
              position: 'center',
              padding: 20,
              textStyle: {
                fontFamily: '微软雅黑',
                fontSize: 24,
                color: '#B7E1FF'
              }
            },
            labelLine: {
              show: false
            }
          }
        ]
      },
      // 内侧大圈
      {
        type: 'pie',
        center: ['20%', '60%'],
        radius: [35, 39],
        x: '0%',
        startAngle: 180,
        hoverAnimation: false,
        data: [
          {
            value: 100,
            itemStyle: { color: 'rgba(128, 132, 135, 1)' },
            label: { show: false },
            labelLine: { show: false }
          },
          {
            value: 20,
            itemStyle: { color: 'rgba(255, 254, 254, 0.0)' },
            label: { show: false },
            labelLine: { show: false }
          }
        ]
      },
    ]
  };
  chart.setOption(option);
},

drawBwfx(){
  var p = this.parkingSpaceInfoData.spaceUseRate?this.parkingSpaceInfoData.spaceUseRate:0
  var inUsed = (this.parkingSpaceInfoData.innerParkingRate &&  this.parkingSpaceInfoData.innerParkingRate.split('/').length==2)?this.parkingSpaceInfoData.innerParkingRate.split('/')[0]:'0'
  var inTotal = (this.parkingSpaceInfoData.innerParkingRate &&  this.parkingSpaceInfoData.innerParkingRate.split('/').length==2)?this.parkingSpaceInfoData.innerParkingRate.split('/')[1]:'0'
  var outUsed = (this.parkingSpaceInfoData.outParkingRate &&  this.parkingSpaceInfoData.outParkingRate.split('/').length==2)?this.parkingSpaceInfoData.outParkingRate.split('/')[0]:'0'
  var outTotal = (this.parkingSpaceInfoData.outParkingRate &&  this.parkingSpaceInfoData.outParkingRate.split('/').length==2)?this.parkingSpaceInfoData.outParkingRate.split('/')[1]:'0'
  var chart = echarts.init(document.querySelector('.bwfxChart'))
  var option = {
    // 额外文字
    graphic: [
      // 泊位利用率数值
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "20%",
        top: "40%",
        style: {
          text: p.toString(),
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 27,
          fontFamily: 'Bb3116',
          lineHeight: 27,
        },
      },
      // 右侧1占比分子
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "81%",
        top: "58%",
        style: {
          text: `${inUsed}`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 14,
          lineHeight: 14,
        },
      },
      // 右侧1占比/
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "88%",
        top: "58%",
        style: {
          text: `/`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 14,
          lineHeight: 14,
        },
      },
      // 右侧1占比分母
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "90%",
        top: "58%",
        style: {
          text: `${inTotal}`,
          textAlign: "center",
          fill: "rgba(24, 254, 254, 1)", //文字的颜色
          fontSize: 14,
          lineHeight: 14,
        },
      },
      // 右侧2占比分子
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "81%",
        top: "76.5%",
        style: {
          text: `${outUsed}`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 14,
          lineHeight: 14,
        },
      },
      // 右侧2占比/
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "88%",
        top: "76.5%",
        style: {
          text: `/`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 14,
          lineHeight: 14,
        },
      },
      // 右侧2占比分母
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "90%",
        top: "76.5%",
        style: {
          text: `${outTotal}`,
          textAlign: "center",
          fill: "rgba(24, 254, 254, 1)", //文字的颜色
          fontSize: 14,
          lineHeight: 14,
        },
      },
      // 路内占比
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "65%",
        top: "58%",
        style: {
          text: `路内占比`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 12,
          lineHeight: 12,
        },
      },
      // 路外占比
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "65%",
        top: "76.5%",
        style: {
          text: `路外占比`,
          textAlign: "center",
          fill: "#FFFFFF", //文字的颜色
          fontSize: 12,
          lineHeight: 12,
        },
      },
      // %
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "32%",
        top: "42%",
        style: {
          text: `%`,
          textAlign: "center",
          fill: "#677681", //文字的颜色
          fontSize: 9,
          lineHeight: 9,
        },
      },
      // 泊位利用率
      {
        //环形图中间添加文字
        type: "text", //通过不同top值可以设置上下显示
        left: "20%",
        top: "55%",
        style: {
          text: `泊位利用率`,
          textAlign: "center",
          fill: "rgba(255, 255, 255, 1)", //文字的颜色
          fontSize: 10,
          lineHeight: 10,
        },
      },
      // // 繁忙
      // {
      //   //环形图中间添加文字
      //   type: "text", //通过不同top值可以设置上下显示
      //   left: "24%",
      //   top: "63%",
      //   style: {
      //     text: `繁忙`,
      //     textAlign: "center",
      //     fill: "rgba(248, 195, 6, 1)", //文字的颜色
      //     fontSize: 9,
      //     lineHeight: 9,
      //   },
      // },
    ],
    series: [
      // 主饼图
      {
        type: 'pie',
        center: ['22%', '50%'],
        radius: [70, 80],
        x: '0%',
        startAngle: -30,
        tooltip: { show: false },
        itemStyle: {
          borderRadius: 10,
          borderColor: 'rgba(6, 255, 165, 0)',
          borderWidth: 10
        },
        data: [
          {
            value: p,
            itemStyle: { color: 'rgba(6, 255, 165, 0.94)' },
            hoverAnimation: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false
            }
          },
          {
            value: 100-p,
            itemStyle: { color: 'rgba(246, 255, 252, 0.94)' },
            hoverAnimation: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false
            }
          }
        ]
      },
      // 右侧小圈1
      {
        type: 'pie',
        center: ['57%', '60%'],
        radius: [15, 18],
        x: '0%',
        data: [
          {
            value: inTotal==='0'?0:(parseInt(inUsed)*100/parseInt(inTotal)),
            itemStyle: { color: 'rgba(255, 254, 254, 1)' },
            label: { show: false },
            labelLine: { show: false }
          },
          {
            value: inTotal==='0'?100:100-(parseInt(inUsed)*100/parseInt(inTotal)),
            itemStyle: { color: 'rgba(255, 254, 254, 0.20)' },
            label: { show: false },
            labelLine: { show: false }
          }
        ]
      },
      // 右侧小圈2
      {
        type: 'pie',
        center: ['57%', '79%'],
        radius: [15, 18],
        x: '0%',
        data: [
          {
            value: outTotal==='0'?0:(parseInt(outUsed)*100/parseInt(outTotal)),
            itemStyle: { color: 'rgba(255, 254, 254, 1)' },
            label: { show: false },
            labelLine: { show: false }
          },
          {
            value: outTotal==='0'?100:100-(parseInt(outUsed)*100/parseInt(outTotal)),
            itemStyle: { color: 'rgba(255, 254, 254, 0.20)' },
            label: { show: false },
            labelLine: { show: false }
          }
        ]
      },
      // 外侧大圈
      {
        type: 'pie',
        center: ['22%', '50%'],
        radius: [90, 92],
        x: '0%',
        startAngle: 100,
        hoverAnimation: false,
        data: [
          {
            value: 100,
            itemStyle: { color: 'rgba(255, 254, 254, 1)' },
            label: { show: false },
            labelLine: { show: false }
          },
          {
            value: 20,
            itemStyle: { color: 'rgba(255, 254, 254, 0.20)' },
            label: { show: false },
            labelLine: { show: false }
          }
        ]
      },
      // 内侧大圈
      {
        type: 'pie',
        center: ['22%', '50%'],
        radius: [58, 63],
        x: '0%',
        hoverAnimation: false,
        data: [
          {
            value: 100,
            itemStyle: { color: 'rgba(255, 255, 255, 0.40)' },
            label: { show: false },
            labelLine: { show: false }
          }
        ]
      }
    ]
  };
  chart.setOption(option);
},

drawJrsrqs(){
  var chart = echarts.init(document.querySelector('.jrsrqsChart'))
  var data = []
  var data1 = []
  var data2 = []
  for(var key in this.parkingFeeChangeData.parkingLotInner[0]){
    data.push(key)
    data1.push(this.parkingFeeChangeData.parkingLotInner[0][key])
    data2.push(this.parkingFeeChangeData.parkingLotOut[0][key])
  }
  var option = {
    height: 160,
    legend: {
      data: ['路内', '路外'],
      icon: "roundRect",
      left:'right',
      top:'10%',
      textStyle:{
        color: '#fff',
      },
    },
    color: ['rgba(0, 100, 251, 1)', 'rgba(8, 252, 223, 1)', '#37A2FF', '#FF0087', '#FFBF00'],
    xAxis: {
      type: 'category',
      splitLine: {
        show: true,
        lineStyle: {
          // type: 'dashed',
          color: ['#3C6579']
        }
      },
      data: data
    },
    yAxis: {
      type: 'value',
      name:'单位:W',
      splitLine: {
        show: false,
        lineStyle: {
          // type: 'dashed',
          color: ['#3C6579']
        }
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    series: [
      {
        name: '路内',
        showSymbol: false,
        data: data1,
        type: 'line',
        smooth: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(0, 100, 251, 0.3)'
            },
            {
              offset: 1,
              color: 'rgba(33, 228, 255, 0.1)'
            }
          ])
        },
      },
      {
        name: '路外',
        showSymbol: false,
        data: data2,
        type: 'line',
        smooth: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(4, 189, 248, 0.3)'
            },
            {
              offset: 1,
              color: 'rgba(8, 252, 223, 0.1)'
            }
          ])
        },
      }
    ]
  };
  chart.setOption(option);
},

drawCcsrTop(){
      setTimeout(()=>{
        if(this.parkingLotSortList.length>0){
          var total = this.parkingLotSortList[0].transactionAmount===0?999999:this.parkingLotSortList[0].transactionAmount
          for (let i = 0; i < 5; i++) {
            var curTotal = this.parkingLotSortList[i].parkingLotName===''?0:this.parkingLotSortList[i].transactionAmount
            var chart = echarts.init(document.querySelector(`.pmBar${i+1}`))
            var option = {
              // width:600,
              grid: {
                left: '2px',
                right: '2px',
                // top: '0px',
                // bottom: '0px',
              },
              xAxis: [{
                show: false,
              },
                {
                  show: false,
                }
              ],
              yAxis: {
                type: 'category',
                axisLabel: {
                  show: false, //让Y轴数据不显示
                },

                axisTick: {
                  show: false, //隐藏Y轴刻度
                },
                axisLine: {
                  show: false, //隐藏Y轴线段
                },
              },
              series: [
                //背景色
                {
                  show: false,
                  type: 'bar',
                  barGap: '-100%',
                  barWidth: '10%', //统计条宽度
                  itemStyle: {
                    normal: {
                      barBorderRadius: 1,
                      color: 'rgba(255, 255, 255, 0.20)'
                    },
                  },
                  z: 1,
                  data: [1],
                },
                //蓝条
                {
                  show: true,
                  type: 'bar',
                  barGap: '-100%',
                  barWidth: '10%', //统计条宽度
                  itemStyle: {
                    normal: {
                      barBorderRadius: 1, //统计条弧度
                      color: {
                        colorStops: [{
                          offset: 0,
                          color: '#5d29f7' // 0% 处的颜色
                        }, {
                          offset: 1,
                          color: '#5093fb' // 100% 处的颜色
                        }],
                        globalCoord: false, // 缺省为 false

                      }
                    },
                  },
                  max: 1,
                  data: [curTotal/total],
                },

              ]
            };
            chart.setOption(option);
          }
        }
      },1000)
},

drawTfscfx(){
  var data = []
  var data1 = []
  for(var key in this.parkingTimeAnylizeData.takeTimeCount[0]){
    data.push(key)
    data1.push(this.parkingTimeAnylizeData.takeTimeCount[0][key])
  }
  var chart = echarts.init(document.querySelector('.tfscfxChart'))
  var option = {
    height: 160,
    xAxis: {
      type: 'category',
      data: data
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: ['#3C6579']
        }
      },
    },
    series: [
      {
        data: data1,
        // type: 'bar',
        type: 'pictorialBar',
        itemStyle: {
          normal: {
            color: 'rgba(7, 247, 161, 1)',
          }
        },
        symbolOffset: [0, -2],
        symbolRepeat: 'fixed',
        symbolClip: true,
        symbolSize: [10, 3.5],
        symbol: 'rect',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      },
      {
        type: 'pictorialBar',
        itemStyle: {
          normal: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        },
        data: data1,
        animationDuration: 0,
        symbolRepeat: 'fixed',
        // symbolMargin: '20%',
        symbol: 'rect',
        symbolSize: [10, 3.5],
        label: {
          show: false,
          position: 'right',
          color: '#fff',
          fontSize: 16,
        }
      }
    ]
  };
  chart.setOption(option);
},

// 中间背景雨
rainBg() {
  var c = document.querySelector(".rain");
  // var c = this.$refs.rain;
  var ctx = c.getContext("2d");//获取canvas上下文
  var w = c.width = document.querySelector('.total').clientWidth;
  var h = c.height = document.querySelector('.total').clientHeight;
  //设置canvas宽、高

  function random(min, max) {
    return Math.random() * (max - min) + min;
  }

  function RainDrop() { }
  //雨滴对象 这是绘制雨滴动画的关键
  RainDrop.prototype = {
    init: function () {
      this.x = random(0, w);//雨滴的位置x
      this.y = h;//雨滴的位置y
      this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
      this.vy = random(4, 5);//雨滴下落速度
      this.hit = 0;//下落的最大值
      this.size = 2;//长方形宽度
    },
    draw: function () {
      if (this.y > this.hit) {
        var linearGradient = ctx.createLinearGradient(this.x, this.y, this.x, this.y + this.size * 30)
        // 设置起始颜色
        linearGradient.addColorStop(0, '#14789c')
        // 设置终止颜色 090723
        linearGradient.addColorStop(1, '#090723')
        // 设置填充样式
        ctx.fillStyle = linearGradient
        ctx.fillRect(this.x, this.y, this.size, this.size * 50);//绘制长方形，通过多次叠加长方形，形成雨滴下落效果
      }
      this.update();//更新位置
    },
    update: function () {
      if (this.y > this.hit) {
        this.y -= this.vy;//未达到底部，增加雨滴y坐标
      } else {
        this.init();
      }
    }
  };

  function resize() {
    w = c.width = window.innerWidth;
    h = c.height = window.innerHeight;
  }

  //初始化一个雨滴
  var rs = []
  for (var i = 0; i < 10; i++) {
    setTimeout(function () {
      var r = new RainDrop();
      r.init();
      rs.push(r);
    }, i * 300)
  }

  function anim() {
    ctx.clearRect(0, 0, w, h);//填充背景色，注意不要用clearRect，否则会清空前面的雨滴，导致不能产生叠加的效果
    for (var i = 0; i < rs.length; i++) {
      rs[i].draw();//绘制雨滴
    }
    requestAnimationFrame(anim);//控制动画帧
  }

  window.addEventListener("resize", resize);
  //启动动画
  anim()
},
// 中间虚线
dashed() {
  var canvas = document.querySelector('.dashed')
  var ctx = canvas.getContext('2d')
  var w = canvas.width = document.querySelector('.total').clientWidth
  var h = canvas.height = document.querySelector('.total').clientHeight / 3 * 2
  ctx.lineWidth = 3
  ctx.setLineDash([3, 3]);
  ctx.fillStyle = '#93f8fb'
  ctx.shadowOffsetX = 0;
  // 阴影的y偏移
  ctx.shadowOffsetY = 0;
  // 阴影颜色
  ctx.shadowColor = '#93f8fb';
  // 阴影的模糊半径
  ctx.shadowBlur = 15;
  ctx.save()  //缓存初始状态
  // 绘制第一条曲线
  ctx.beginPath()
  var grd = ctx.createLinearGradient(w / 11 * 2, h / 3, w / 5 * 2, h);
  grd.addColorStop(0, "#54e2e6");
  grd.addColorStop(1, "#065261");
  ctx.strokeStyle = grd;
  ctx.moveTo(w / 2, h)
  ctx.quadraticCurveTo(w / 5, h, w / 12 * 1.5, h / 3*2);
  ctx.stroke();
  // 绘制第一条曲线上的圆光效果
  ctx.beginPath()
  ctx.moveTo(w / 12 * 1.5, h / 3*2)
  ctx.arc(w / 12 * 1.5, h / 3*2, 5, 0, Math.PI * 2)
  ctx.fill()
  ctx.restore()
  ctx.save()
  // 绘制第二条线
  ctx.beginPath()
  grd = ctx.createLinearGradient(w / 11 * 3.3, h / 2, w / 3 * 1.1, h / 6 * 5);
  grd.addColorStop(0, "#e08d03");
  grd.addColorStop(1, "#2e6a5c");
  ctx.strokeStyle = grd;
  ctx.moveTo(w / 2, h)
  ctx.quadraticCurveTo(w / 5, h / 6 * 4.2, w / 11 * 2, h / 2.5);
  ctx.stroke();
  // 绘制第二条曲线上的圆光效果
  ctx.beginPath()
  ctx.moveTo(w / 11 * 2, h / 2.5)
  ctx.arc(w / 11 * 2, h / 2.5, 5, 0, Math.PI * 2)
  ctx.fill()
  ctx.restore()
  ctx.save()
  // 绘制第三条线
  ctx.beginPath()
  grd = ctx.createLinearGradient(w / 3 * 1.4, h / 5, w / 5 * 2, h / 2);
  grd.addColorStop(0, "#54e2e6");
  grd.addColorStop(1, "#065261");
  ctx.strokeStyle = grd;
  ctx.moveTo(w / 2, h )
  ctx.quadraticCurveTo(w / 5*1.5, h / 6 * 5, w / 11 * 3, h / 5);
  ctx.stroke();
  // 绘制第三条曲线上的圆光效果
  ctx.beginPath()
  ctx.moveTo(w / 11*3, h / 5)
  ctx.arc(w / 11 * 3, h / 5, 5, 0, Math.PI * 2)
  ctx.fill()
  ctx.restore()
  ctx.save()
  // 绘制第四条线
  ctx.beginPath()
  grd = ctx.createLinearGradient(w / 5 * 3.1, h / 3*1.2, w / 5 * 3.2, h / 2 * 1.5);
  grd.addColorStop(0, "#e08d03");
  grd.addColorStop(1, "#2e6a5c");
  ctx.strokeStyle = grd;
  ctx.moveTo(w / 2, h)
  ctx.quadraticCurveTo(w / 5 * 3.5, h / 6*5, w / 11*8, h / 5);
  ctx.stroke();
  // 绘制第四条曲线上的圆光效果
  ctx.beginPath()
  ctx.moveTo( w / 11*8, h / 5)
  ctx.arc( w / 11*8, h / 5, 5, 0, Math.PI * 2)
  ctx.fill()
  ctx.restore()
  ctx.save()
  // 绘制第五条线
  ctx.beginPath()
  grd = ctx.createLinearGradient(w / 5 * 3.3, h / 4, w / 5 * 3.2, h / 2 * 1.9);
  grd.addColorStop(0, "#54e2e6");
  grd.addColorStop(1, "#065261");
  ctx.strokeStyle = grd;
  ctx.moveTo(w / 2, h)
  ctx.quadraticCurveTo(w / 5 * 4, h / 6 * 4.2, w / 11*9, h / 2.5);
  ctx.stroke();
  // 绘制第五条曲线上的圆光效果
  ctx.beginPath()
  ctx.moveTo(w / 11*9, h/2.5)
  ctx.arc(w / 11*9, h / 2.5, 5, 0, Math.PI * 2)
  ctx.fill()
  ctx.restore()
  ctx.save()
  // 绘制第六条线
  ctx.beginPath()
  grd = ctx.createLinearGradient(w / 5 * 3.8, h / 2*1.2, w / 5 * 2.9, h);
  grd.addColorStop(0, "#e08d03");
  grd.addColorStop(1, "#2e6a5c");
  ctx.strokeStyle = grd;
  ctx.moveTo(w / 2, h)
  ctx.quadraticCurveTo(w / 5 * 4, h, w /12*10.5, h / 3*2);
  ctx.stroke();
  // 绘制第六条曲线上的圆光效果
  ctx.beginPath()
  ctx.moveTo(w / 12*10.5, h/3*2)
  ctx.arc(w / 12*10.5, h / 3*2, 5, 0, Math.PI * 2)
  ctx.fill()
},
  },
};
</script>

<style scoped lang="css">
* {
  margin: 0;
  padding: 0;
}
html,
.body {
  z-index: -9;
  width: 100%;
  overflow: hidden;
  height: 100%;
  margin-top: 3px;
  padding-top: 50px;
  background: url(../../assets/imagesTemp/53bg.png) no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: row;
}
ul,
li {
  list-style: none;
}
@font-face {
  font-family: 'LCdd';
  src: url('../../assets/fonts/LCdd.TTF');
}
@font-face {
  font-family: 'Bb3116';
  src: url('../../assets/fonts/bb3116.ttf');
}
header {
  height: 70px;
  width: 100%;
  background-color: #fff;
  background: url(../../assets/images/screenTitleBg.png) no-repeat top center;
  background-size: 100%;
  //text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
header h1 {
  font-size: 2rem;
  height: 50%;
  margin: auto;
  display: table;
  font-weight: 500;
  background-image: -webkit-linear-gradient(bottom, #86919e, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
header h1 span {
  display: table-cell;
  vertical-align: middle;
}
header p {
  font-size: 1rem;
  background-image: -webkit-linear-gradient(bottom, #86919e, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

header .header-title{
  //width: 463px;
  //height: 43px;
  font-size: 35px;
  font-family: FZLanTingHeiS-B-GB, FZLanTingHeiS-B-GB;
  font-weight: 400;
  color: #08FFFF;
  line-height: 42px;
  letter-spacing: 7px;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
}
header .curDateTime{
  position: absolute;
  right: 3%;
  display: flex;
  color: #DBEAEA;
  font-size: 18px;
  font-family: Bb3116;
  font-weight: lighter;
}

header .curDateTime .curTime{
  margin-left: 24px;
}
.main {
  width: 100%;
  height: 100%;
  background: #0B0E14;
  //padding: 0 2.5%;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}
.main .top5 {
  width: 25%;
  height: 70%;
  float: left;
}
.main .top5 .top5-title {
  width: 90%;
  height: 44px;
  background: url(../../assets/screen/title.png) no-repeat center;
  background-size: 100%;
  color: #fff;
  //text-align: center;
  display: table;
  padding-left: 34px;
  font-size: 16px;
}
.main .top5 .top5-title span {
  display: table-cell;
  vertical-align: middle;
}
.main .top5 .top5-content {
  width: 100%;
  height: 240px;
}
.main .top5 .top5-content .xjrwgk{
  margin-left: 15px;
  padding: 16px;
   background: url(../../assets/screen/xjrw_bg.png) no-repeat;
 }
.main .top5 .top5-content .pmno1,
.main .top5 .top5-content .pmno2,
.main .top5 .top5-content .pmno3,
.main .top5 .top5-content .pmno4,
.main .top5 .top5-content .pmno5{
  width: 33px;
  height: 37.5px;
  color: white;
  font-size: 14px;
  font-family: Bb3116;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/imagesTemp/top_bg.png) no-repeat;
}
.main .top5 .top5-content .pmno4,
.main .top5 .top5-content .pmno5
{
  background: none;
}
.main .top5 .top5-content .pmno4{
  color: rgba(16, 252, 255, 1);
}
.main .top5 .top5-content ul {
  width: 100%;
  height: 100%;
}
.main .top5 .top5-content ul li {
  width: 100%;
  height: 15%;
  margin-top: 5%;
  position: relative;
}
.main .top5 .top5-content ul li .li-content {
  width: 90%;
  height: 100%;
  margin-left: 5%;
  background: url(../../assets/imagesTemp/border.png) no-repeat center;
  background-size: contain;
  font-size: 0.7rem;
  padding-left: 15%;
  padding-top: 6%;
  color: #fff;
  box-sizing: border-box;
}
.main .top5 .top5-content ul li .li-content span {
  margin-right: 2%;
}

.main .top5 .top5-content ul li .cicle {
  content: '';
  display: block;
  position: absolute;
  width: 1.7rem;
  height: 1.7rem;
  background: url(../../assets/imagesTemp/green.png) no-repeat center;
  background-size: 100%;
  left: 0;
  bottom: 0;
}
.main .top5 .top5-content ul li:nth-of-type(1) .li-content {
  background: url(../../assets/imagesTemp/border2.png) no-repeat center;
  background-size: contain;
}
.main .top5 .top5-content ul li:nth-of-type(1) .cicle {
  background: url(../../assets/imagesTemp/orange.png) no-repeat center;
  background-size: 100%;
}

.main .summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.60);
  width: 913px;
  height: 77px;
  background: url(../../assets/images/screen_summary_bg.png) no-repeat center;
  //margin-top: 37px;
}

.main .summary .content{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 759px;
  height: 77px;
}

.main .summary .content .summary-item .item-val{
  margin-top: 8px;
  color: white;
  font-size: 26px;
  font-family: Bb3116;
  display: flex;
}

.main .summary .content .summary-item .item-val .item-pic {
  width: 7px;
  height: 21px;
  margin-left: 7px;
  background: url(../../assets/images/screen_persent1.png) no-repeat center;
}

.main .summary .content .summary-item .item-val .item-pre{
  margin-left: 4px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.60);
}

.main .summary .pic-left{
  width: 77px;
  height: 77px;
  background: url(../../assets/images/screen_summary_left.png) no-repeat center;
  animation: rotate 10s linear infinite;
}

.main .summary .pic-right{
  width: 77px;
  height: 77px;
  background: url(../../assets/images/screen_summary_right.png) no-repeat center;
  animation: rotate 20s linear infinite;
}

.main .total {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}

.main .rain {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.main .total .data1,
.main .total .data2,
.main .total .data3,
.main .total .data4,
.main .total .data5,
.main .total .data6 {
  width: 8rem;
  height: 4rem;
  position: absolute;
}
.main .total .data1 span,
.main .total .data2 span,
.main .total .data3 span,
.main .total .data4 span,
.main .total .data5 span,
.main .total .data6 span {
  color: #0ac1c7;
  font-size: 0.8rem;
  font-family: '宋体';
}
.main .total .data1 p,
.main .total .data2 p,
.main .total .data3 p,
.main .total .data4 p,
.main .total .data5 p,
.main .total .data6 p {
  font-family: 'LCdd';
  font-size: 1.5rem;
  color: #f29701;
}
.main .total .data1 {
  left: 8%;
  top: 37%;
}
.main .total .data2 {
  left: 14%;
  top: 19%;
}
.main .total .data3 {
  left: 25%;
  top: 6%;
}
.main .total .data4 {
  left: 70%;
  top: 6%;
}
.main .total .data5 {
  left: 78%;
  top: 19%;
}
.main .total .data6 {
  left: 85%;
  top: 37%;
}
.main .total .dashed {
  position: absolute;
  left: 0;
  top: 0;
}
.main .total .sphere {
  width: 20rem;
  height: 20rem;
  position: relative;
  margin: 14% auto 0;
}
.main .total .sphere .sphere-bg {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/imagesTemp/53earth.png) no-repeat center;
  background-size: contain;
}
.main .total .sphere .sum {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/imagesTemp/53cloud.png) no-repeat center;
  background-size: 55%;
}
.main .total .sphere .sum span {
  display: block;
  margin-top: 30%;
  padding-left: 32%;
  color: #005a79;
  font-size: 0.9rem;
}
.main .total .sphere .sum p {
  margin-top: 2%;
  text-align: center;
  color: #003c63;
  font-family: 'LCdd';
  font-size: 2rem;
}
.main .total .cicle3 {
  width: 35rem;
  height: 35rem;
  background: url(../../assets/imagesTemp/circle.png) no-repeat center;
  background-size: 100%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate3 20s linear infinite;
}
.main .total .cicle4 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 60%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(../../assets/imagesTemp/53gqright.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate3 2s linear infinite;
}
.main .total .cicle5 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 62%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(../../assets/imagesTemp/53gqleft.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate4 2s linear infinite;
}
.main .total .cicle6 {
  width: 12rem;
  height: 12rem;
  position: absolute;
  top: 70%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(../../assets/imagesTemp/535gqbottomright.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate3 2s linear infinite;
}
.main .total .cicle7 {
  width: 12rem;
  height: 12rem;
  position: absolute;
  top: 72%;
  left: 50%;
  transform-style: preserve-3d;
  background: url(../../assets/imagesTemp/53gqbottomleft.png) no-repeat center;
  background-size: 100%;
  transform: translateX(-50%) rotateX(75deg);
  animation: rotate4 2s linear infinite;
}
.main .total .cicle8,
.main .total .cicle9,
.main .total .cicle10,
.main .total .cicle11,
.main .total .cicle12
{
  width: 5rem;
  height: 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin-left: -2.5rem;
  border-radius: 50%;
}
.main .total .cicle8 span,
.main .total .cicle9 span,
.main .total .cicle10 span,
.main .total .cicle11 span,
.main .total .cicle12 span {
  font-family: 'LCdd';
  display: block;
  //margin-left: 25%;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 20%;
}
.main .total .cicle8 p,
.main .total .cicle9 p,
.main .total .cicle10 p,
.main .total .cicle11 p,
.main .total .cicle12 p {
  text-align: center;
  font-size: 0.7rem;
}
.main .total .cicle8 {
  background: url(../../assets/imagesTemp/circle1.png) no-repeat;
  background-size: 100%;
  animation: rotate5 20s linear infinite;
  color: #f29701;
}
.main .total .cicle9 {
  background: url(../../assets/imagesTemp/circle2.png) no-repeat;
  background-size: 100%;
  animation: rotate6 20s linear infinite;
  color: #0ac1c7;
}
.main .total .cicle10 {
  background: url(../../assets/imagesTemp/circle1.png) no-repeat;
  background-size: 100%;
  animation: rotate7 20s linear infinite;
  color: #f29701;
}
.main .total .cicle11 {
  background: url(../../assets/imagesTemp/circle2.png) no-repeat;
  background-size: 100%;
  color: #0ac1c7;
  animation: rotate8 20s linear infinite;
}
.main .total .cicle12 {
  background: url(../../assets/imagesTemp/circle2.png) no-repeat;
  background-size: 100%;
  color: #0ac1c7;
  animation: rotate8 20s linear infinite;
}
.main .analyse {
  width: 25%;
  height: 70%;
  float: right;
}
.main .analyse .tfscfxChart, .main .analyse .jrsrqsChart{
  width: 95%;
  //height: 50%;
  height: 240px;
  float: left;
}
.main .analyse .jrssdd{
  width: 100%;
//height: 50%;
  height: 240px;
  float: left;
  color: white;
  font-size: 14px;
  margin-top: 18px;
}
.main .analyse .jrssdd .jrssdd-item{
  display: flex;
  height: 30px;
  padding: 0 7px;
  background: url(../../assets/screen/list-item.png) no-repeat;
  justify-content: space-between;
  align-items: center;
}

.main .analyse .analyse-title {
  width: 90%;
  height: 44px;
  background: url(../../assets/screen/title.png) no-repeat center;
  background-size: 100%;
  color: #fff;
  //text-align: center;
  display: table;
  padding-left: 34px;
  font-size: 16px;
}
.main .analyse .analyse-title span {
  display: table-cell;
  vertical-align: middle;
}
.main .analyse ul {
  width: 100%;
  height: 60%;
}
.main .analyse ul li {
  width: 100%;
  height: 30%;
  margin: 3% 0;
}
.main .analyse ul li img {
  height: 80%;
  float: left;
  vertical-align: 0%;
}
.main .analyse ul li h5 {
  float: left;
  color: #fff;
  margin-left: 5%;
}
.main .analyse ul li span {
  font-size: 0.5rem;
  float: left;
  color: #fff;
  margin-left: 5%;
}
.main .analyse ul li em {
  font-style: normal;
  font-size: 1.3rem;
  margin-left: 5%;
  color: #0ac1c7;
}
.main .analyse ul li:nth-of-type(1) em {
  color: #f29701;
}
.main .analyse .execution {
  width: 100%;
  height: 30%;
  background: url(../../assets/imagesTemp/execution.png) no-repeat;
  background-size: contain;
  overflow: hidden;
  position: relative;
}
.main .analyse .execution .title {
  writing-mode: vertical-lr;
  font-size: 0.7rem;
  margin-left: 3%;
  margin-top: 3%;
  color: #fff;
}
.main .analyse .execution .cicle1,
.main .analyse .execution .cicle2 {
  position: absolute;
  width: 4rem;
  height: 4rem;
  background: url(../../assets/imagesTemp/2222.png) no-repeat center;
  background-size: 100%;
  transform-style: preserve-3d;
  transform: rotateX(75deg);
  animation: rotate2 1s linear infinite;
}
.main .analyse .execution .cicle1 {
  top: 47%;
  left: 20%;
}
.main .analyse .execution .cicle2 {
  top: 47%;
  left: 63%;
}
.main .analyse .execution .waterChart1,
.main .analyse .execution .waterChart2 {
  position: absolute;
  width: 4rem;
  height: 5rem;
}
.main .analyse .execution .waterChart1 .chart-title,
.main .analyse .execution .waterChart2 .chart-title {
  height: 20%;
  font-size: 0.6rem;
  text-align: center;
  color: #fff;
}
.main .analyse .execution .waterChart1 .chart1,
.main .analyse .execution .waterChart2 .chart1,
.main .analyse .execution .waterChart1 .chart2,
.main .analyse .execution .waterChart2 .chart2 {
  width: 100%;
  height: 80%;
}
.main .analyse .execution .waterChart1 {
  top: 0%;
  left: 20%;
}
.main .analyse .execution .waterChart2 {
  top: 0%;
  left: 63%;
}
.main .sale {
  width: 27.5%;
  height: 30%;
  position: absolute;
  bottom: 0;
  left: 2.5%;
}
.main .sale ul {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main .sale ul li {
  width: 100%;
  height: 30%;
  margin-top: 1%;
}
.main .sale ul li .showImg {
  float: left;
  width: 10%;
  height: 100%;
  text-align: center;
}
.main .sale ul li .showImg img {
  vertical-align: 0%;
  width: 70%;
  margin-top: 30%;
}
.main .sale ul li .data {
  float: left;
  width: 10%;
  height: 100%;
  text-align: center;
}
.main .sale ul li .data span {
  color: #fff;
  font-size: 0.6rem;
}
.main .sale ul li .data span:nth-of-type(2) {
  color: #0ac1c7;
}
.main .sale ul li .shoeChart,
.main .sale ul li .clothesChart,
.main .sale ul li .mzChart {
  height: 100%;
  width: 75%;
  float: left;
}
.bottom {
  width: 100%;
  height: 10%;
  background: url(../../assets/imagesTemp/53bottomsjbg.png) no-repeat bottom center;
  background-size: 100%;
  text-align: center;
}
.bottom h5 {
  background-image: -webkit-linear-gradient(bottom, #86919e, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 40%;
  display: table;
  margin: auto;
}
.bottom h5 span {
  display: table-cell;
  vertical-align: middle;
}
.bottom p {
  color: #0ac1c7;
  font-size: 0.6rem;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: rotateX(75deg) rotateZ(360deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(360deg);
  }
}
@keyframes rotate4 {
  0% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(0);
  }
  100% {
    transform: translateX(-50%) rotateX(75deg) rotateZ(-360deg);
  }
}
/*版权所有   青岛研锦网络科技有限公司*/
@keyframes rotate5 {
  0% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  1% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, 269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  2% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, 267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  3% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, 265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  4% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, 261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  5% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, 256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  6% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, 251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  7% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, 244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  8% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, 236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  9% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, 227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  10% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, 218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  11% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, 208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  12% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, 196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  13% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, 184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  14% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, 172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  15% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, 158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  16% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, 144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  17% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, 130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  18% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, 114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  19% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, 99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  20% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, 83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  21% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, 67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  22% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, 50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  23% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, 33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  24% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, 16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  25% {
    transform: matrix3d(1.23333, 0, 0, 0, 0, 1.23333, 0, 0, 0, 0, 1, 0, 0, 100, 0, 1);
    z-index: 101;
  }
  26% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, -16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  27% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, -33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  28% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, -50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  29% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, -67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  30% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, -83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  31% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, -99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  32% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, -114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  33% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, -130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  34% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, -144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  35% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, -158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  36% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, -172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  37% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, -184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  38% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, -196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  39% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, -208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  40% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, -218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  41% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, -227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  42% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, -236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  43% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, -244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  44% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, -251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  45% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, -256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  46% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, -261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  47% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, -265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  48% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, -267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  49% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, -269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  50% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  51% {
    transform: matrix3d(0.87907, 0, 0, 0, 0, 0.87907, 0, 0, 0, 0, 1, 0, -269.46722, -6.27905, 0, 1);
    z-index: 99.93721;
  }
  52% {
    transform: matrix3d(0.85822, 0, 0, 0, 0, 0.85822, 0, 0, 0, 0, 1, 0, -267.87097, -12.53332, 0, 1);
    z-index: 99.87467;
  }
  53% {
    transform: matrix3d(0.83754, 0, 0, 0, 0, 0.83754, 0, 0, 0, 0, 1, 0, -265.21756, -18.73813, 0, 1);
    z-index: 99.81262;
  }
  54% {
    transform: matrix3d(0.8171, 0, 0, 0, 0, 0.8171, 0, 0, 0, 0, 1, 0, -261.51745, -24.86899, 0, 1);
    z-index: 99.75131;
  }
  55% {
    transform: matrix3d(0.79699, 0, 0, 0, 0, 0.79699, 0, 0, 0, 0, 1, 0, -256.78526, -30.9017, 0, 1);
    z-index: 99.69098;
  }
  56% {
    transform: matrix3d(0.77729, 0, 0, 0, 0, 0.77729, 0, 0, 0, 0, 1, 0, -251.03965, -36.81246, 0, 1);
    z-index: 99.63188;
  }
  57% {
    transform: matrix3d(0.75807, 0, 0, 0, 0, 0.75807, 0, 0, 0, 0, 1, 0, -244.3033, -42.57793, 0, 1);
    z-index: 99.57422;
  }
  58% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, -236.6028, -48.17537, 0, 1);
    z-index: 99.51825;
  }
  59% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, -227.96854, -53.58268, 0, 1);
    z-index: 99.46417;
  }
  60% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, -218.43459, -58.77853, 0, 1);
    z-index: 99.41221;
  }
  61% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, -208.03857, -63.7424, 0, 1);
    z-index: 99.36258;
  }
  62% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, -196.82153, -68.45471, 0, 1);
    z-index: 99.31545;
  }
  63% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, -184.82772, -72.89686, 0, 1);
    z-index: 99.27103;
  }
  64% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, -172.10447, -77.05132, 0, 1);
    z-index: 99.22949;
  }
  65% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, -158.70201, -80.9017, 0, 1);
    z-index: 99.19098;
  }
  66% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, -144.67323, -84.43279, 0, 1);
    z-index: 99.15567;
  }
  67% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, -130.07348, -87.63067, 0, 1);
    z-index: 99.12369;
  }
  68% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, -114.96039, -90.4827, 0, 1);
    z-index: 99.09517;
  }
  69% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, -99.39361, -92.97765, 0, 1);
    z-index: 99.07022;
  }
  70% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, -83.43456, -95.10565, 0, 1);
    z-index: 99.04894;
  }
  71% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, -67.14622, -96.85831, 0, 1);
    z-index: 99.03142;
  }
  72% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, -50.59289, -98.22872, 0, 1);
    z-index: 99.01771;
  }
  73% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, -33.83989, -99.21146, 0, 1);
    z-index: 99.00789;
  }
  74% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, -16.95333, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  75% {
    transform: matrix3d(0.56667, 0, 0, 0, 0, 0.56667, 0, 0, 0, 0, 1, 0, 0.00015, -99.99999, 0, 1);
    z-index: 99;
  }
  76% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, 16.95364, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  77% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, 33.84024, -99.21145, 0, 1);
    z-index: 99.00789;
  }
  78% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, 50.59331, -98.2287, 0, 1);
    z-index: 99.01771;
  }
  79% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, 67.14674, -96.85828, 0, 1);
    z-index: 99.03142;
  }
  80% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, 83.4352, -95.1056, 0, 1);
    z-index: 99.04894;
  }
  81% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, 99.39444, -92.97758, 0, 1);
    z-index: 99.07022;
  }
  82% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, 114.96147, -90.48262, 0, 1);
    z-index: 99.09517;
  }
  83% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, 130.07487, -87.63055, 0, 1);
    z-index: 99.12369;
  }
  84% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, 144.67503, -84.43264, 0, 1);
    z-index: 99.15567;
  }
  85% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, 158.70434, -80.9015, 0, 1);
    z-index: 99.19099;
  }
  86% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, 172.10748, -77.05106, 0, 1);
    z-index: 99.22949;
  }
  87% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, 184.83158, -72.89652, 0, 1);
    z-index: 99.27103;
  }
  88% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, 196.82649, -68.45427, 0, 1);
    z-index: 99.31546;
  }
  89% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, 208.04493, -63.74182, 0, 1);
    z-index: 99.36258;
  }
  90% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, 218.4427, -58.77778, 0, 1);
    z-index: 99.41222;
  }
  91% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, 227.97887, -53.58172, 0, 1);
    z-index: 99.46418;
  }
  92% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, 236.61593, -48.17414, 0, 1);
    z-index: 99.51826;
  }
  93% {
    transform: matrix3d(0.75808, 0, 0, 0, 0, 0.75808, 0, 0, 0, 0, 1, 0, 244.31993, -42.57636, 0, 1);
    z-index: 99.57424;
  }
  94% {
    transform: matrix3d(0.7773, 0, 0, 0, 0, 0.7773, 0, 0, 0, 0, 1, 0, 251.06067, -36.81045, 0, 1);
    z-index: 99.6319;
  }
  95% {
    transform: matrix3d(0.797, 0, 0, 0, 0, 0.797, 0, 0, 0, 0, 1, 0, 256.81175, -30.89914, 0, 1);
    z-index: 99.69101;
  }
  96% {
    transform: matrix3d(0.81711, 0, 0, 0, 0, 0.81711, 0, 0, 0, 0, 1, 0, 261.55076, -24.86574, 0, 1);
    z-index: 99.75134;
  }
  97% {
    transform: matrix3d(0.83755, 0, 0, 0, 0, 0.83755, 0, 0, 0, 0, 1, 0, 265.25934, -18.73401, 0, 1);
    z-index: 99.81266;
  }
  98% {
    transform: matrix3d(0.85824, 0, 0, 0, 0, 0.85824, 0, 0, 0, 0, 1, 0, 267.92326, -12.52811, 0, 1);
    z-index: 99.87472;
  }
  99% {
    transform: matrix3d(0.87909, 0, 0, 0, 0, 0.87909, 0, 0, 0, 0, 1, 0, 269.5325, -6.27248, 0, 1);
    z-index: 99.93728;
  }
  100% {
    transform: matrix3d(0.90003, 0, 0, 0, 0, 0.90003, 0, 0, 0, 0, 1, 0, 270.08133, 0.00827, 0, 1);
    z-index: 100.00008;
  }
}
@keyframes rotate6 {
  0% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, -16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  1% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, -33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  2% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, -50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  3% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, -67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  4% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, -83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  5% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, -99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  6% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, -114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  7% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, -130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  8% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, -144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  9% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, -158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  10% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, -172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  11% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, -184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  12% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, -196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  13% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, -208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  14% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, -218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  15% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, -227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  16% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, -236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  17% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, -244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  18% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, -251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  19% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, -256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  20% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, -261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  21% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, -265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  22% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, -267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  23% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, -269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  24% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  25% {
    transform: matrix3d(0.87907, 0, 0, 0, 0, 0.87907, 0, 0, 0, 0, 1, 0, -269.46722, -6.27905, 0, 1);
    z-index: 99.93721;
  }
  26% {
    transform: matrix3d(0.85822, 0, 0, 0, 0, 0.85822, 0, 0, 0, 0, 1, 0, -267.87097, -12.53332, 0, 1);
    z-index: 99.87467;
  }
  27% {
    transform: matrix3d(0.83754, 0, 0, 0, 0, 0.83754, 0, 0, 0, 0, 1, 0, -265.21756, -18.73813, 0, 1);
    z-index: 99.81262;
  }
  28% {
    transform: matrix3d(0.8171, 0, 0, 0, 0, 0.8171, 0, 0, 0, 0, 1, 0, -261.51745, -24.86899, 0, 1);
    z-index: 99.75131;
  }
  29% {
    transform: matrix3d(0.79699, 0, 0, 0, 0, 0.79699, 0, 0, 0, 0, 1, 0, -256.78526, -30.9017, 0, 1);
    z-index: 99.69098;
  }
  30% {
    transform: matrix3d(0.77729, 0, 0, 0, 0, 0.77729, 0, 0, 0, 0, 1, 0, -251.03965, -36.81246, 0, 1);
    z-index: 99.63188;
  }
  31% {
    transform: matrix3d(0.75807, 0, 0, 0, 0, 0.75807, 0, 0, 0, 0, 1, 0, -244.3033, -42.57793, 0, 1);
    z-index: 99.57422;
  }
  32% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, -236.6028, -48.17537, 0, 1);
    z-index: 99.51825;
  }
  33% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, -227.96854, -53.58268, 0, 1);
    z-index: 99.46417;
  }
  34% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, -218.43459, -58.77853, 0, 1);
    z-index: 99.41221;
  }
  35% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, -208.03857, -63.7424, 0, 1);
    z-index: 99.36258;
  }
  36% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, -196.82153, -68.45471, 0, 1);
    z-index: 99.31545;
  }
  37% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, -184.82772, -72.89686, 0, 1);
    z-index: 99.27103;
  }
  38% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, -172.10447, -77.05132, 0, 1);
    z-index: 99.22949;
  }
  39% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, -158.70201, -80.9017, 0, 1);
    z-index: 99.19098;
  }
  40% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, -144.67323, -84.43279, 0, 1);
    z-index: 99.15567;
  }
  41% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, -130.07348, -87.63067, 0, 1);
    z-index: 99.12369;
  }
  42% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, -114.96039, -90.4827, 0, 1);
    z-index: 99.09517;
  }
  43% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, -99.39361, -92.97765, 0, 1);
    z-index: 99.07022;
  }
  44% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, -83.43456, -95.10565, 0, 1);
    z-index: 99.04894;
  }
  45% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, -67.14622, -96.85831, 0, 1);
    z-index: 99.03142;
  }
  46% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, -50.59289, -98.22872, 0, 1);
    z-index: 99.01771;
  }
  47% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, -33.83989, -99.21146, 0, 1);
    z-index: 99.00789;
  }
  48% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, -16.95333, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  49% {
    transform: matrix3d(0.56667, 0, 0, 0, 0, 0.56667, 0, 0, 0, 0, 1, 0, 0.00015, -99.99999, 0, 1);
    z-index: 99;
  }
  50% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, 16.95364, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  51% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, 33.84024, -99.21145, 0, 1);
    z-index: 99.00789;
  }
  52% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, 50.59331, -98.2287, 0, 1);
    z-index: 99.01771;
  }
  53% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, 67.14674, -96.85828, 0, 1);
    z-index: 99.03142;
  }
  54% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, 83.4352, -95.1056, 0, 1);
    z-index: 99.04894;
  }
  55% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, 99.39444, -92.97758, 0, 1);
    z-index: 99.07022;
  }
  56% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, 114.96147, -90.48262, 0, 1);
    z-index: 99.09517;
  }
  57% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, 130.07487, -87.63055, 0, 1);
    z-index: 99.12369;
  }
  58% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, 144.67503, -84.43264, 0, 1);
    z-index: 99.15567;
  }
  59% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, 158.70434, -80.9015, 0, 1);
    z-index: 99.19099;
  }
  60% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, 172.10748, -77.05106, 0, 1);
    z-index: 99.22949;
  }
  61% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, 184.83158, -72.89652, 0, 1);
    z-index: 99.27103;
  }
  62% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, 196.82649, -68.45427, 0, 1);
    z-index: 99.31546;
  }
  63% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, 208.04493, -63.74182, 0, 1);
    z-index: 99.36258;
  }
  64% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, 218.4427, -58.77778, 0, 1);
    z-index: 99.41222;
  }
  65% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, 227.97887, -53.58172, 0, 1);
    z-index: 99.46418;
  }
  66% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, 236.61593, -48.17414, 0, 1);
    z-index: 99.51826;
  }
  67% {
    transform: matrix3d(0.75808, 0, 0, 0, 0, 0.75808, 0, 0, 0, 0, 1, 0, 244.31993, -42.57636, 0, 1);
    z-index: 99.57424;
  }
  68% {
    transform: matrix3d(0.7773, 0, 0, 0, 0, 0.7773, 0, 0, 0, 0, 1, 0, 251.06067, -36.81045, 0, 1);
    z-index: 99.6319;
  }
  69% {
    transform: matrix3d(0.797, 0, 0, 0, 0, 0.797, 0, 0, 0, 0, 1, 0, 256.81175, -30.89914, 0, 1);
    z-index: 99.69101;
  }
  70% {
    transform: matrix3d(0.81711, 0, 0, 0, 0, 0.81711, 0, 0, 0, 0, 1, 0, 261.55076, -24.86574, 0, 1);
    z-index: 99.75134;
  }
  71% {
    transform: matrix3d(0.83755, 0, 0, 0, 0, 0.83755, 0, 0, 0, 0, 1, 0, 265.25934, -18.73401, 0, 1);
    z-index: 99.81266;
  }
  72% {
    transform: matrix3d(0.85824, 0, 0, 0, 0, 0.85824, 0, 0, 0, 0, 1, 0, 267.92326, -12.52811, 0, 1);
    z-index: 99.87472;
  }
  73% {
    transform: matrix3d(0.87909, 0, 0, 0, 0, 0.87909, 0, 0, 0, 0, 1, 0, 269.5325, -6.27248, 0, 1);
    z-index: 99.93728;
  }
  74% {
    transform: matrix3d(0.90003, 0, 0, 0, 0, 0.90003, 0, 0, 0, 0, 1, 0, 270.08133, 0.00827, 0, 1);
    z-index: 100.00008;
  }
  75% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  76% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, 269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  77% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, 267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  78% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, 265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  79% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, 261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  80% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, 256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  81% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, 251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  82% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, 244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  83% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, 236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  84% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, 227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  85% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, 218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  86% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, 208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  87% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, 196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  88% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, 184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  89% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, 172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  90% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, 158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  91% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, 144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  92% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, 130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  93% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, 114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  94% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, 99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  95% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, 83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  96% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, 67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  97% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, 50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  98% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, 33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  99% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, 16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  100% {
    transform: matrix3d(1.23333, 0, 0, 0, 0, 1.23333, 0, 0, 0, 0, 1, 0, 0, 100, 0, 1);
    z-index: 101;
  }
}
@keyframes rotate7 {
  0% {
    transform: matrix3d(0.85822, 0, 0, 0, 0, 0.85822, 0, 0, 0, 0, 1, 0, -267.87097, -12.53332, 0, 1);
    z-index: 99.87467;
  }
  1% {
    transform: matrix3d(0.83754, 0, 0, 0, 0, 0.83754, 0, 0, 0, 0, 1, 0, -265.21756, -18.73813, 0, 1);
    z-index: 99.81262;
  }
  2% {
    transform: matrix3d(0.8171, 0, 0, 0, 0, 0.8171, 0, 0, 0, 0, 1, 0, -261.51745, -24.86899, 0, 1);
    z-index: 99.75131;
  }
  3% {
    transform: matrix3d(0.79699, 0, 0, 0, 0, 0.79699, 0, 0, 0, 0, 1, 0, -256.78526, -30.9017, 0, 1);
    z-index: 99.69098;
  }
  4% {
    transform: matrix3d(0.77729, 0, 0, 0, 0, 0.77729, 0, 0, 0, 0, 1, 0, -251.03965, -36.81246, 0, 1);
    z-index: 99.63188;
  }
  5% {
    transform: matrix3d(0.75807, 0, 0, 0, 0, 0.75807, 0, 0, 0, 0, 1, 0, -244.3033, -42.57793, 0, 1);
    z-index: 99.57422;
  }
  6% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, -236.6028, -48.17537, 0, 1);
    z-index: 99.51825;
  }
  7% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, -227.96854, -53.58268, 0, 1);
    z-index: 99.46417;
  }
  8% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, -218.43459, -58.77853, 0, 1);
    z-index: 99.41221;
  }
  9% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, -208.03857, -63.7424, 0, 1);
    z-index: 99.36258;
  }
  10% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, -196.82153, -68.45471, 0, 1);
    z-index: 99.31545;
  }
  11% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, -184.82772, -72.89686, 0, 1);
    z-index: 99.27103;
  }
  12% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, -172.10447, -77.05132, 0, 1);
    z-index: 99.22949;
  }
  13% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, -158.70201, -80.9017, 0, 1);
    z-index: 99.19098;
  }
  14% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, -144.67323, -84.43279, 0, 1);
    z-index: 99.15567;
  }
  15% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, -130.07348, -87.63067, 0, 1);
    z-index: 99.12369;
  }
  16% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, -114.96039, -90.4827, 0, 1);
    z-index: 99.09517;
  }
  17% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, -99.39361, -92.97765, 0, 1);
    z-index: 99.07022;
  }
  18% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, -83.43456, -95.10565, 0, 1);
    z-index: 99.04894;
  }
  19% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, -67.14622, -96.85831, 0, 1);
    z-index: 99.03142;
  }
  20% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, -50.59289, -98.22872, 0, 1);
    z-index: 99.01771;
  }
  21% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, -33.83989, -99.21146, 0, 1);
    z-index: 99.00789;
  }
  22% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, -16.95333, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  23% {
    transform: matrix3d(0.56667, 0, 0, 0, 0, 0.56667, 0, 0, 0, 0, 1, 0, 0.00015, -99.99999, 0, 1);
    z-index: 99;
  }
  24% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, 16.95364, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  25% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, 33.84024, -99.21145, 0, 1);
    z-index: 99.00789;
  }
  26% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, 50.59331, -98.2287, 0, 1);
    z-index: 99.01771;
  }
  27% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, 67.14674, -96.85828, 0, 1);
    z-index: 99.03142;
  }
  28% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, 83.4352, -95.1056, 0, 1);
    z-index: 99.04894;
  }
  29% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, 99.39444, -92.97758, 0, 1);
    z-index: 99.07022;
  }
  30% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, 114.96147, -90.48262, 0, 1);
    z-index: 99.09517;
  }
  31% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, 130.07487, -87.63055, 0, 1);
    z-index: 99.12369;
  }
  32% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, 144.67503, -84.43264, 0, 1);
    z-index: 99.15567;
  }
  33% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, 158.70434, -80.9015, 0, 1);
    z-index: 99.19099;
  }
  34% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, 172.10748, -77.05106, 0, 1);
    z-index: 99.22949;
  }
  35% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, 184.83158, -72.89652, 0, 1);
    z-index: 99.27103;
  }
  36% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, 196.82649, -68.45427, 0, 1);
    z-index: 99.31546;
  }
  37% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, 208.04493, -63.74182, 0, 1);
    z-index: 99.36258;
  }
  38% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, 218.4427, -58.77778, 0, 1);
    z-index: 99.41222;
  }
  39% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, 227.97887, -53.58172, 0, 1);
    z-index: 99.46418;
  }
  40% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, 236.61593, -48.17414, 0, 1);
    z-index: 99.51826;
  }
  41% {
    transform: matrix3d(0.75808, 0, 0, 0, 0, 0.75808, 0, 0, 0, 0, 1, 0, 244.31993, -42.57636, 0, 1);
    z-index: 99.57424;
  }
  42% {
    transform: matrix3d(0.7773, 0, 0, 0, 0, 0.7773, 0, 0, 0, 0, 1, 0, 251.06067, -36.81045, 0, 1);
    z-index: 99.6319;
  }
  43% {
    transform: matrix3d(0.797, 0, 0, 0, 0, 0.797, 0, 0, 0, 0, 1, 0, 256.81175, -30.89914, 0, 1);
    z-index: 99.69101;
  }
  44% {
    transform: matrix3d(0.81711, 0, 0, 0, 0, 0.81711, 0, 0, 0, 0, 1, 0, 261.55076, -24.86574, 0, 1);
    z-index: 99.75134;
  }
  45% {
    transform: matrix3d(0.83755, 0, 0, 0, 0, 0.83755, 0, 0, 0, 0, 1, 0, 265.25934, -18.73401, 0, 1);
    z-index: 99.81266;
  }
  46% {
    transform: matrix3d(0.85824, 0, 0, 0, 0, 0.85824, 0, 0, 0, 0, 1, 0, 267.92326, -12.52811, 0, 1);
    z-index: 99.87472;
  }
  47% {
    transform: matrix3d(0.87909, 0, 0, 0, 0, 0.87909, 0, 0, 0, 0, 1, 0, 269.5325, -6.27248, 0, 1);
    z-index: 99.93728;
  }
  48% {
    transform: matrix3d(0.90003, 0, 0, 0, 0, 0.90003, 0, 0, 0, 0, 1, 0, 270.08133, 0.00827, 0, 1);
    z-index: 100.00008;
  }
  49% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  50% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, 269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  51% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, 267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  52% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, 265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  53% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, 261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  54% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, 256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  55% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, 251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  56% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, 244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  57% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, 236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  58% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, 227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  59% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, 218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  60% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, 208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  61% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, 196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  62% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, 184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  63% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, 172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  64% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, 158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  65% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, 144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  66% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, 130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  67% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, 114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  68% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, 99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  69% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, 83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  70% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, 67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  71% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, 50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  72% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, 33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  73% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, 16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  74% {
    transform: matrix3d(1.23333, 0, 0, 0, 0, 1.23333, 0, 0, 0, 0, 1, 0, 0, 100, 0, 1);
    z-index: 101;
  }
  75% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, -16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  76% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, -33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  77% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, -50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  78% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, -67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  79% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, -83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  80% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, -99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  81% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, -114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  82% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, -130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  83% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, -144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  84% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, -158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  85% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, -172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  86% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, -184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  87% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, -196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  88% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, -208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  89% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, -218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  90% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, -227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  91% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, -236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  92% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, -244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  93% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, -251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  94% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, -256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  95% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, -261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  96% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, -265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  97% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, -267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  98% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, -269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  99% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  100% {
    transform: matrix3d(0.87907, 0, 0, 0, 0, 0.87907, 0, 0, 0, 0, 1, 0, -269.46722, -6.27905, 0, 1);
    z-index: 99.93721;
  }
}
@keyframes rotate8 {
  0% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, 50.59331, -98.2287, 0, 1);
    z-index: 99.01771;
  }
  1% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, 67.14674, -96.85828, 0, 1);
    z-index: 99.03142;
  }
  2% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, 83.4352, -95.1056, 0, 1);
    z-index: 99.04894;
  }
  3% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, 99.39444, -92.97758, 0, 1);
    z-index: 99.07022;
  }
  4% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, 114.96147, -90.48262, 0, 1);
    z-index: 99.09517;
  }
  5% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, 130.07487, -87.63055, 0, 1);
    z-index: 99.12369;
  }
  6% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, 144.67503, -84.43264, 0, 1);
    z-index: 99.15567;
  }
  7% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, 158.70434, -80.9015, 0, 1);
    z-index: 99.19099;
  }
  8% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, 172.10748, -77.05106, 0, 1);
    z-index: 99.22949;
  }
  9% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, 184.83158, -72.89652, 0, 1);
    z-index: 99.27103;
  }
  10% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, 196.82649, -68.45427, 0, 1);
    z-index: 99.31546;
  }
  11% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, 208.04493, -63.74182, 0, 1);
    z-index: 99.36258;
  }
  12% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, 218.4427, -58.77778, 0, 1);
    z-index: 99.41222;
  }
  13% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, 227.97887, -53.58172, 0, 1);
    z-index: 99.46418;
  }
  14% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, 236.61593, -48.17414, 0, 1);
    z-index: 99.51826;
  }
  15% {
    transform: matrix3d(0.75808, 0, 0, 0, 0, 0.75808, 0, 0, 0, 0, 1, 0, 244.31993, -42.57636, 0, 1);
    z-index: 99.57424;
  }
  16% {
    transform: matrix3d(0.7773, 0, 0, 0, 0, 0.7773, 0, 0, 0, 0, 1, 0, 251.06067, -36.81045, 0, 1);
    z-index: 99.6319;
  }
  17% {
    transform: matrix3d(0.797, 0, 0, 0, 0, 0.797, 0, 0, 0, 0, 1, 0, 256.81175, -30.89914, 0, 1);
    z-index: 99.69101;
  }
  18% {
    transform: matrix3d(0.81711, 0, 0, 0, 0, 0.81711, 0, 0, 0, 0, 1, 0, 261.55076, -24.86574, 0, 1);
    z-index: 99.75134;
  }
  19% {
    transform: matrix3d(0.83755, 0, 0, 0, 0, 0.83755, 0, 0, 0, 0, 1, 0, 265.25934, -18.73401, 0, 1);
    z-index: 99.81266;
  }
  20% {
    transform: matrix3d(0.85824, 0, 0, 0, 0, 0.85824, 0, 0, 0, 0, 1, 0, 267.92326, -12.52811, 0, 1);
    z-index: 99.87472;
  }
  21% {
    transform: matrix3d(0.87909, 0, 0, 0, 0, 0.87909, 0, 0, 0, 0, 1, 0, 269.5325, -6.27248, 0, 1);
    z-index: 99.93728;
  }
  22% {
    transform: matrix3d(0.90003, 0, 0, 0, 0, 0.90003, 0, 0, 0, 0, 1, 0, 270.08133, 0.00827, 0, 1);
    z-index: 100.00008;
  }
  23% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 270, 0, 0, 1);
    z-index: 100;
  }
  24% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, 269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  25% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, 267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  26% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, 265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  27% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, 261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  28% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, 256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  29% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, 251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  30% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, 244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  31% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, 236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  32% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, 227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  33% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, 218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  34% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, 208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  35% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, 196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  36% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, 184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  37% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, 172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  38% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, 158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  39% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, 144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  40% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, 130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  41% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, 114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  42% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, 99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  43% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, 83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  44% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, 67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  45% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, 50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  46% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, 33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  47% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, 16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  48% {
    transform: matrix3d(1.23333, 0, 0, 0, 0, 1.23333, 0, 0, 0, 0, 1, 0, 0, 100, 0, 1);
    z-index: 101;
  }
  49% {
    transform: matrix3d(1.23268, 0, 0, 0, 0, 1.23268, 0, 0, 0, 0, 1, 0, -16.95344, 99.80267, 0, 1);
    z-index: 100.99803;
  }
  50% {
    transform: matrix3d(1.2307, 0, 0, 0, 0, 1.2307, 0, 0, 0, 0, 1, 0, -33.83997, 99.21147, 0, 1);
    z-index: 100.99211;
  }
  51% {
    transform: matrix3d(1.22743, 0, 0, 0, 0, 1.22743, 0, 0, 0, 0, 1, 0, -50.59295, 98.22873, 0, 1);
    z-index: 100.98229;
  }
  52% {
    transform: matrix3d(1.22286, 0, 0, 0, 0, 1.22286, 0, 0, 0, 0, 1, 0, -67.14627, 96.85832, 0, 1);
    z-index: 100.96858;
  }
  53% {
    transform: matrix3d(1.21702, 0, 0, 0, 0, 1.21702, 0, 0, 0, 0, 1, 0, -83.43459, 95.10565, 0, 1);
    z-index: 100.95106;
  }
  54% {
    transform: matrix3d(1.20993, 0, 0, 0, 0, 1.20993, 0, 0, 0, 0, 1, 0, -99.39363, 92.97765, 0, 1);
    z-index: 100.92978;
  }
  55% {
    transform: matrix3d(1.20161, 0, 0, 0, 0, 1.20161, 0, 0, 0, 0, 1, 0, -114.96041, 90.48271, 0, 1);
    z-index: 100.90483;
  }
  56% {
    transform: matrix3d(1.1921, 0, 0, 0, 0, 1.1921, 0, 0, 0, 0, 1, 0, -130.07349, 87.63067, 0, 1);
    z-index: 100.87631;
  }
  57% {
    transform: matrix3d(1.18144, 0, 0, 0, 0, 1.18144, 0, 0, 0, 0, 1, 0, -144.67323, 84.43279, 0, 1);
    z-index: 100.84433;
  }
  58% {
    transform: matrix3d(1.16967, 0, 0, 0, 0, 1.16967, 0, 0, 0, 0, 1, 0, -158.70202, 80.9017, 0, 1);
    z-index: 100.80902;
  }
  59% {
    transform: matrix3d(1.15684, 0, 0, 0, 0, 1.15684, 0, 0, 0, 0, 1, 0, -172.10448, 77.05132, 0, 1);
    z-index: 100.77051;
  }
  60% {
    transform: matrix3d(1.14299, 0, 0, 0, 0, 1.14299, 0, 0, 0, 0, 1, 0, -184.82772, 72.89686, 0, 1);
    z-index: 100.72897;
  }
  61% {
    transform: matrix3d(1.12818, 0, 0, 0, 0, 1.12818, 0, 0, 0, 0, 1, 0, -196.82153, 68.45471, 0, 1);
    z-index: 100.68455;
  }
  62% {
    transform: matrix3d(1.11247, 0, 0, 0, 0, 1.11247, 0, 0, 0, 0, 1, 0, -208.03858, 63.7424, 0, 1);
    z-index: 100.63742;
  }
  63% {
    transform: matrix3d(1.09593, 0, 0, 0, 0, 1.09593, 0, 0, 0, 0, 1, 0, -218.43459, 58.77853, 0, 1);
    z-index: 100.58779;
  }
  64% {
    transform: matrix3d(1.07861, 0, 0, 0, 0, 1.07861, 0, 0, 0, 0, 1, 0, -227.96854, 53.58268, 0, 1);
    z-index: 100.53583;
  }
  65% {
    transform: matrix3d(1.06058, 0, 0, 0, 0, 1.06058, 0, 0, 0, 0, 1, 0, -236.6028, 48.17537, 0, 1);
    z-index: 100.48175;
  }
  66% {
    transform: matrix3d(1.04193, 0, 0, 0, 0, 1.04193, 0, 0, 0, 0, 1, 0, -244.3033, 42.57793, 0, 1);
    z-index: 100.42578;
  }
  67% {
    transform: matrix3d(1.02271, 0, 0, 0, 0, 1.02271, 0, 0, 0, 0, 1, 0, -251.03965, 36.81246, 0, 1);
    z-index: 100.36812;
  }
  68% {
    transform: matrix3d(1.00301, 0, 0, 0, 0, 1.00301, 0, 0, 0, 0, 1, 0, -256.78526, 30.9017, 0, 1);
    z-index: 100.30902;
  }
  69% {
    transform: matrix3d(0.9829, 0, 0, 0, 0, 0.9829, 0, 0, 0, 0, 1, 0, -261.51745, 24.86899, 0, 1);
    z-index: 100.24869;
  }
  70% {
    transform: matrix3d(0.96246, 0, 0, 0, 0, 0.96246, 0, 0, 0, 0, 1, 0, -265.21756, 18.73813, 0, 1);
    z-index: 100.18738;
  }
  71% {
    transform: matrix3d(0.94178, 0, 0, 0, 0, 0.94178, 0, 0, 0, 0, 1, 0, -267.87097, 12.53332, 0, 1);
    z-index: 100.12533;
  }
  72% {
    transform: matrix3d(0.92093, 0, 0, 0, 0, 0.92093, 0, 0, 0, 0, 1, 0, -269.46722, 6.27905, 0, 1);
    z-index: 100.06279;
  }
  73% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, -270, 0, 0, 1);
    z-index: 100;
  }
  74% {
    transform: matrix3d(0.87907, 0, 0, 0, 0, 0.87907, 0, 0, 0, 0, 1, 0, -269.46722, -6.27905, 0, 1);
    z-index: 99.93721;
  }
  75% {
    transform: matrix3d(0.85822, 0, 0, 0, 0, 0.85822, 0, 0, 0, 0, 1, 0, -267.87097, -12.53332, 0, 1);
    z-index: 99.87467;
  }
  76% {
    transform: matrix3d(0.83754, 0, 0, 0, 0, 0.83754, 0, 0, 0, 0, 1, 0, -265.21756, -18.73813, 0, 1);
    z-index: 99.81262;
  }
  77% {
    transform: matrix3d(0.8171, 0, 0, 0, 0, 0.8171, 0, 0, 0, 0, 1, 0, -261.51745, -24.86899, 0, 1);
    z-index: 99.75131;
  }
  78% {
    transform: matrix3d(0.79699, 0, 0, 0, 0, 0.79699, 0, 0, 0, 0, 1, 0, -256.78526, -30.9017, 0, 1);
    z-index: 99.69098;
  }
  79% {
    transform: matrix3d(0.77729, 0, 0, 0, 0, 0.77729, 0, 0, 0, 0, 1, 0, -251.03965, -36.81246, 0, 1);
    z-index: 99.63188;
  }
  80% {
    transform: matrix3d(0.75807, 0, 0, 0, 0, 0.75807, 0, 0, 0, 0, 1, 0, -244.3033, -42.57793, 0, 1);
    z-index: 99.57422;
  }
  81% {
    transform: matrix3d(0.73942, 0, 0, 0, 0, 0.73942, 0, 0, 0, 0, 1, 0, -236.6028, -48.17537, 0, 1);
    z-index: 99.51825;
  }
  82% {
    transform: matrix3d(0.72139, 0, 0, 0, 0, 0.72139, 0, 0, 0, 0, 1, 0, -227.96854, -53.58268, 0, 1);
    z-index: 99.46417;
  }
  83% {
    transform: matrix3d(0.70407, 0, 0, 0, 0, 0.70407, 0, 0, 0, 0, 1, 0, -218.43459, -58.77853, 0, 1);
    z-index: 99.41221;
  }
  84% {
    transform: matrix3d(0.68753, 0, 0, 0, 0, 0.68753, 0, 0, 0, 0, 1, 0, -208.03857, -63.7424, 0, 1);
    z-index: 99.36258;
  }
  85% {
    transform: matrix3d(0.67182, 0, 0, 0, 0, 0.67182, 0, 0, 0, 0, 1, 0, -196.82153, -68.45471, 0, 1);
    z-index: 99.31545;
  }
  86% {
    transform: matrix3d(0.65701, 0, 0, 0, 0, 0.65701, 0, 0, 0, 0, 1, 0, -184.82772, -72.89686, 0, 1);
    z-index: 99.27103;
  }
  87% {
    transform: matrix3d(0.64316, 0, 0, 0, 0, 0.64316, 0, 0, 0, 0, 1, 0, -172.10447, -77.05132, 0, 1);
    z-index: 99.22949;
  }
  88% {
    transform: matrix3d(0.63033, 0, 0, 0, 0, 0.63033, 0, 0, 0, 0, 1, 0, -158.70201, -80.9017, 0, 1);
    z-index: 99.19098;
  }
  89% {
    transform: matrix3d(0.61856, 0, 0, 0, 0, 0.61856, 0, 0, 0, 0, 1, 0, -144.67323, -84.43279, 0, 1);
    z-index: 99.15567;
  }
  90% {
    transform: matrix3d(0.6079, 0, 0, 0, 0, 0.6079, 0, 0, 0, 0, 1, 0, -130.07348, -87.63067, 0, 1);
    z-index: 99.12369;
  }
  91% {
    transform: matrix3d(0.59839, 0, 0, 0, 0, 0.59839, 0, 0, 0, 0, 1, 0, -114.96039, -90.4827, 0, 1);
    z-index: 99.09517;
  }
  92% {
    transform: matrix3d(0.59007, 0, 0, 0, 0, 0.59007, 0, 0, 0, 0, 1, 0, -99.39361, -92.97765, 0, 1);
    z-index: 99.07022;
  }
  93% {
    transform: matrix3d(0.58298, 0, 0, 0, 0, 0.58298, 0, 0, 0, 0, 1, 0, -83.43456, -95.10565, 0, 1);
    z-index: 99.04894;
  }
  94% {
    transform: matrix3d(0.57714, 0, 0, 0, 0, 0.57714, 0, 0, 0, 0, 1, 0, -67.14622, -96.85831, 0, 1);
    z-index: 99.03142;
  }
  95% {
    transform: matrix3d(0.57257, 0, 0, 0, 0, 0.57257, 0, 0, 0, 0, 1, 0, -50.59289, -98.22872, 0, 1);
    z-index: 99.01771;
  }
  96% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, -33.83989, -99.21146, 0, 1);
    z-index: 99.00789;
  }
  97% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, -16.95333, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  98% {
    transform: matrix3d(0.56667, 0, 0, 0, 0, 0.56667, 0, 0, 0, 0, 1, 0, 0.00015, -99.99999, 0, 1);
    z-index: 99;
  }
  99% {
    transform: matrix3d(0.56732, 0, 0, 0, 0, 0.56732, 0, 0, 0, 0, 1, 0, 16.95364, -99.80266, 0, 1);
    z-index: 99.00197;
  }
  100% {
    transform: matrix3d(0.5693, 0, 0, 0, 0, 0.5693, 0, 0, 0, 0, 1, 0, 33.84024, -99.21145, 0, 1);
    z-index: 99.00789;
  }
}

</style>
