import request from '@/utils/request'
import {objectToQueryString} from "@/utils/utils";

// 大屏-收益及收缴率
export function parkingRevenue(){
    return request.post('/data/parkingRevenue');
}

// 大屏-泊位相关数据统计
export function parkingSpaceInfo(){
    return request.post('/data/parkingSpaceInfo');
}

// 大屏-服务车次、累计收益、欠费收益
export function parkingFeeInfo(){
    return request.post('/data/parkingFeeInfo');
}

// 大屏-车场数统计 路内 路外
export function getParkingLotTotalResult(){
    return request.post('/data/getParkingLotTotalResult');
}

// 大屏-泊位数统计 路内 路外
export function getParkingSpaceTotalResult(){
    return request.post('/data/getParkingSpaceTotalResult');
}

// 大屏-车辆数 平均时长
export function getParkingVehicleInResult(){
    return request.post('/data/getParkingVehicleInResult');
}

// 大屏-车场收入排名
export function searchParkingLotSortList(params){
    return request.post('/data/searchParkingLotSortList?' + objectToQueryString(params))
}

// 大屏-今日收入趋势
export function searchParkingFeeChange(params){
    return request.post('/data/searchParkingFeeChange?' + objectToQueryString(params))
}
// 大屏-停放时长分析
export function searchParkingTimeAnylize(){
    return request.post('/data/searchParkingTimeAnylize');
}
// 大屏-今日实时订单
export function searchParkingorderList(){
    return request.post('/data/searchParkingorderList');
}
// 大屏-巡检任务分析
export function inspectorTaskAnylize(){
    return request.post('/data/inspectorTaskAnylize');
}

// 大屏-巡检任务数
export function inspectorTaskCount(){
    return request.post('/data/inspectorTaskCount');
}
// 大屏-设备投入数
export function deviceTotalCount(){
    return request.post('/data/deviceTotalCount');
}
// 大屏-人员投入数
export function personnelTotalCount(){
    return request.post('/data/personnelTotalCount');
}
